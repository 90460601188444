import { History, LocationState } from "history";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ConfigData, translate } from "../../config";
import { useMessages } from "../../context/MessagesContext";
import { Item, Params, groupByFirstLetter } from "../../model";
import Badge from "../Badge/Badge";
import Fadeable from "../Fadeable/Fadeable";
import Flag from "../Flag/Flag";
import HeadlineGroup from "../HeadlineGroup/HeadlineGroup";
import HeroHeadline from "../HeroHeadline/HeroHeadline";
import Link from "../Link/Link";
import Search from "../Search/Search";
import SetTitle from "../SetTitle";

import FilterSelect from "../FilterSelection/FilterSelect";
import getUniqueTags from "../FilterSelection/helpers";
import TeamGroupFilter from "../TeamGroupFilter/TeamGroupFilter";

const containsSearchTerm = (text = "", term = "") => {
  // TODO search refinement
  return (
    text.trim().toLocaleLowerCase().indexOf(term.trim().toLocaleLowerCase()) !==
    -1
  );
};

type PageOverviewProps = {
  rings: readonly ("all" | string)[];
  search: string;
  items: Item[];
  config: ConfigData;
  leaving: boolean;
  onLeave: () => void;
};

export default function PageOverview({
  rings,
  search: searchProp,
  items,
  config,
  leaving,
  onLeave,
}: PageOverviewProps) {
  const [ring, setRing] = useState<string | "all">("all");
  const [search, setSearch] = useState(searchProp);
  const { pageOverview } = useMessages();
  const title = pageOverview?.title || "Technologies Overview";

  const searchParams = new URLSearchParams(window.location.search);
  const searchTags: string[] = searchParams.getAll("tags");

  const itemTags: string[] = getUniqueTags(items);

  const filteringTags: string[] = searchTags.filter((tag: string) =>
    itemTags.includes(tag)
  );

  const filteredItems = items.filter((item: Item) => {
    const noFilters = !filteringTags.length;
    const itemTags = item.tags || [];

    return noFilters || itemTags.some((tag) => filteringTags.includes(tag));
  });

  const { namespace, team, page } = useParams<Params>();

  useEffect(() => {
    if (rings.length > 0) {
      setRing(rings[0]);
    }
    setSearch(searchProp);
  }, [rings, searchProp]);

  const handleRingClick = (ring: string) => () => {
    setRing(ring);
  };

  const isRingActive = (ringName: string) => ring === ringName;

  const itemMatchesRing = (item: Item) => ring === "all" || item.ring === ring;

  const itemMatchesSearch = (item: Item) => {
    return (
      search.trim() === "" ||
      containsSearchTerm(item.title, search) ||
      containsSearchTerm(item.body, search) ||
      containsSearchTerm(item.info, search)
    );
  };

  const isItemVisible = (item: Item) =>
    itemMatchesRing(item) && itemMatchesSearch(item);

  const handleSearchTermChange = setSearch;

  const tempGroups = groupByFirstLetter(filteredItems);
  const groupsFiltered = tempGroups.map((group) => ({
    ...group,
    items: group.items.filter(isItemVisible),
  }));
  const groups = groupsFiltered.filter((group) => group.items.length > 0);

  const history = useHistory<History<LocationState>>();

  const handleGroupChange = (selection: string) => {
    history.replace({
      pathname: `/${selection}/_/${page}`,
    });
  };

  const handleTeamChange = (selection: string) => {
    history.replace({
      pathname: `/${namespace}/${selection}/${page}`,
    });
  };

  return (
    <Fadeable leaving={leaving} onLeave={onLeave}>
      <SetTitle title={title} />
      <HeadlineGroup>
        <HeroHeadline>{title}</HeroHeadline>
        <TeamGroupFilter
          selectedGroup={namespace}
          options={config.pageCategories}
          onGroupChange={handleGroupChange}
          onTeamChange={handleTeamChange}
          selectedTeam={team}
        ></TeamGroupFilter>
      </HeadlineGroup>
      <div className="filter">
        <div className="split split--filter">
          <div className="split__left">
            <Search onChange={handleSearchTermChange} value={search} />
          </div>
          <div className="split__right">
            <div className="nav">
              {rings.map((ringName) => (
                <div className="nav__item" key={ringName}>
                  <Badge
                    big
                    onClick={handleRingClick(ringName)}
                    type={isRingActive(ringName) ? ringName : "empty"}
                  >
                    {ringName}
                  </Badge>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <FilterSelect items={items} key={namespace} />
      <div className="letter-index">
        {groups.map(({ letter, items }) => (
          <div key={letter} className="letter-index__group">
            <div className="letter-index__letter">{letter}</div>
            <div className="letter-index__items">
              <div className="item-list">
                <div className="item-list__list">
                  {items.map((item) => (
                    <Link
                      key={item.name}
                      className="item item--big item--no-leading-border item--no-trailing-border"
                      to={`${item.quadrant}/${item.name}`}
                    >
                      <div className="split split--overview">
                        <div className="split__left">
                          <div className="item__title">
                            {item.title}
                            <Flag item={item} />
                          </div>
                        </div>
                        <div className="split__right">
                          <div className="nav nav--relations">
                            <div className="nav__item">
                              {translate(config, item.quadrant)}
                            </div>
                            <div className="nav__item">
                              <Badge type={item.ring}>{item.ring}</Badge>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fadeable>
  );
}
