import React from "react";
import { Link as RLink, useParams } from "react-router-dom";

import { Params } from "../../model";
import "./link.scss";

type LinkProps = {
  to: string;
  style?: React.CSSProperties;
  className?: string;
};

function Link({
  to,
  children,
  className,
  style = {},
}: React.PropsWithChildren<LinkProps>) {
  const { namespace, team } = useParams<Params>();
  return (
    <RLink to={`/${namespace}/${team}/${to}`} style={style} {...{ className }}>
      {children}
    </RLink>
  );
}

export default Link;
