import { History, LocationState } from "history";
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ConfigData } from "../../config";
import { nameDecoder } from "../../helpers/dashSpaceReplace";
import { groupByQuadrants, Item, Params } from "../../model";
import Fadeable from "../Fadeable/Fadeable";
import HeadlineGroup from "../HeadlineGroup/HeadlineGroup";
import HeroHeadline from "../HeroHeadline/HeroHeadline";
import SetTitle from "../SetTitle";
import TeamGroupFilter from "../TeamGroupFilter/TeamGroupFilter";
import ComparisonTable from "./ComparisonTable";
import "./PageComparison.scss";

type ComparisonType = "groups" | "teams";

const getGroupedItems = (
  items: Item[],
  group1: string,
  group2: string,
  groupCommon: boolean,
  group1Type: ComparisonType,
  group2Type: ComparisonType
) => {
  const groupItems = groupByQuadrants(
    items.filter(
      (x: Item) =>
        x[group1Type]?.includes(group1) &&
        (groupCommon
          ? x[group2Type]?.includes(group2)
          : !x[group2Type]?.includes(group2))
    )
  );

  const result = Object.keys(groupItems)
    .map((x) => ({
      key: x,
      value: Object.values(groupItems[x]).flatMap((y) => y),
    }))
    .reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    ) as any;

  return result;
};

export default function PageComparison({
  onLeave,
  leaving,
  items,
  config,
}: {
  onLeave: () => void;
  leaving: boolean;
  items: Item[];
  config: ConfigData;
}) {
  const history = useHistory<History<LocationState>>();
  const { page, namespace, team } = useParams<Params>();

  const searchParams = new URLSearchParams(window.location.search);

  const comparisonType = searchParams.get("type") ?? "groups";
  const comparisonGroup = searchParams.get("group") ?? "_";
  const comparisonTeam = searchParams.get("team") ?? "_";
  const comparisonValue =
    comparisonType === "groups" ? comparisonGroup : comparisonTeam;

  const pageCategories = config.pageCategories;

  const type = team === "_" ? "groups" : "teams";

  const groupLeft =
    team === "_" ? nameDecoder(namespace.replace("_", "")) : nameDecoder(team);

  const groupRight = comparisonValue.replace("_", "") ?? "_";

  const groupOneItems = getGroupedItems(
    items,
    groupLeft,
    groupRight,
    false,
    type,
    comparisonType as ComparisonType
  );

  const groupTwoItems = getGroupedItems(
    items,
    groupRight,
    groupLeft,
    false,
    comparisonType as ComparisonType,
    type
  );

  const commonItems = getGroupedItems(
    items,
    groupLeft,
    groupRight,
    true,
    type,
    comparisonType as ComparisonType
  );

  const handleGroupChange = (selection: string) => {
    const searchParams = new URLSearchParams();

    searchParams.append("type", comparisonTeam === "_" ? "groups" : "teams");
    searchParams.append("group", comparisonGroup);

    if (comparisonTeam !== "_") {
      searchParams.append("team", comparisonTeam);
    }

    history.replace({
      pathname: `/${selection}/_/${page}`,
      search: searchParams.toString(),
    });
  };

  const handleTeamChange = (selection: string) => {
    const searchParams = new URLSearchParams();

    searchParams.append("type", comparisonTeam === "_" ? "groups" : "teams");
    searchParams.append("group", comparisonGroup);

    if (comparisonTeam !== "_") {
      searchParams.append("team", comparisonTeam);
    }

    history.replace({
      pathname: `/${namespace}/${selection}/${page}`,
      search: searchParams.toString(),
    });
  };

  const handleComparisonGroupChange = (selection: string) => {
    const searchParams = new URLSearchParams();

    searchParams.append("type", "groups");
    searchParams.append("group", nameDecoder(selection));

    history.replace({
      search: searchParams.toString(),
    });
  };

  const handleComparisonTeamChange = (selection: string) => {
    const searchParams = new URLSearchParams();

    searchParams.append("type", selection === "_" ? "groups" : "teams");
    searchParams.append("group", comparisonGroup);

    if (selection !== "_") {
      searchParams.append("team", nameDecoder(selection));
    }

    history.replace({
      search: searchParams.toString(),
    });
  };

  return (
    <Fadeable leaving={leaving} onLeave={onLeave}>
      <div className="page-comparison">
        <SetTitle title="Tech Comparison" />
        <HeadlineGroup>
          <HeroHeadline>Tech Comparison</HeroHeadline>
        </HeadlineGroup>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <TeamGroupFilter
              selectedGroup={namespace}
              options={pageCategories}
              onGroupChange={handleGroupChange}
              onTeamChange={handleTeamChange}
              selectedTeam={team}
            ></TeamGroupFilter>
          </div>
          <div>
            <TeamGroupFilter
              selectedGroup={comparisonGroup}
              options={pageCategories}
              onGroupChange={handleComparisonGroupChange}
              onTeamChange={handleComparisonTeamChange}
              selectedTeam={comparisonTeam}
            ></TeamGroupFilter>
          </div>
        </div>
        <ComparisonTable
          groupOneItems={groupOneItems}
          commonItems={commonItems}
          groupTwoItems={groupTwoItems}
        ></ComparisonTable>
      </div>
      <ReactTooltip className="tooltip" offset={{ top: -5 }} bodyMode />
    </Fadeable>
  );
}
