import { MomentInput } from "moment";
import { useHistory, useParams } from "react-router-dom";
import { ConfigData, radarName, radarNameShort } from "../../config";
import { useMessages } from "../../context/MessagesContext";
import { formatRelease } from "../../date";
import { HomepageOption, Item, Params, featuredOnly } from "../../model";
import Fadeable from "../Fadeable/Fadeable";
import HeroHeadline from "../HeroHeadline/HeroHeadline";
import QuadrantGrid from "../QuadrantGrid/QuadrantGrid";
import RadarGrid from "../RadarGrid/RadarGrid";
import SetTitle from "../SetTitle";

import FilterSelect from "../FilterSelection/FilterSelect";
import getUniqueTags from "../FilterSelection/helpers";

import { History, LocationState } from "history";
import TeamGroupFilter from "../TeamGroupFilter/TeamGroupFilter";
import "./PageIndex.scss";

type PageIndexProps = {
  groupName: string;
  leaving: boolean;
  onLeave: () => void;
  items: Item[];
  config: ConfigData;
  releases: MomentInput[];
};

export default function PageIndex({
  leaving,
  onLeave,
  items,
  config,
  releases,
}: PageIndexProps) {
  const history = useHistory<History<LocationState>>();

  const { pageIndex } = useMessages();
  const publishedLabel = pageIndex?.publishedLabel || "Published";

  const newestRelease = releases.slice(-1)[0];
  const numberOfReleases = releases.length;
  const showChart =
    config.homepageContent === HomepageOption.chart ||
    config.homepageContent === HomepageOption.both;
  const showColumns =
    config.homepageContent === HomepageOption.columns ||
    config.homepageContent === HomepageOption.both;

  const searchParams = new URLSearchParams(window.location.search);
  const searchTags: string[] = searchParams.getAll("tags");

  const itemTags: string[] = getUniqueTags(items);

  const filteringTags: string[] = searchTags.filter((tag: string) =>
    itemTags.includes(tag)
  );

  const filteredItems = items.filter((item: Item) => {
    const noFilters = !filteringTags.length;
    const itemTags = item.tags || [];

    return noFilters || itemTags.some((tag) => filteringTags.includes(tag));
  });

  const { namespace, team, page } = useParams<Params>();

  const handleGroupChange = (selection: string) => {
    history.replace({
      pathname: `/${selection}/_/${page}`,
    });
  };

  const handleTeamChange = (selection: string) => {
    history.replace({
      pathname: `/${namespace}/${selection}/${page}`,
    });
  };

  return (
    <Fadeable leaving={leaving} onLeave={onLeave}>
      <SetTitle title={radarNameShort} />
      <div className="headline-group">
        <HeroHeadline alt={`Version #${numberOfReleases}`}>
          {radarName}
        </HeroHeadline>
        <div className="filter-box index-page">
          <FilterSelect items={items} key={namespace} />
          <TeamGroupFilter
            selectedGroup={namespace}
            options={config.pageCategories}
            onGroupChange={handleGroupChange}
            onTeamChange={handleTeamChange}
            selectedTeam={team}
          ></TeamGroupFilter>
        </div>
      </div>
      {showChart && (
        <RadarGrid items={featuredOnly(filteredItems)} config={config} />
      )}
      {showColumns && (
        <QuadrantGrid items={featuredOnly(filteredItems)} config={config} />
      )}
      <div className="publish-date">
        {publishedLabel} {formatRelease(newestRelease, config.dateFormat)}
      </div>
    </Fadeable>
  );
}
