import { Item } from "../../model";

const rows = [
  {
    label: "Languages & Frameworks",
    value: "languages-and-frameworks",
    color: "#0E8B93",
  },
  {
    label: "Methods & Patterns",
    value: "methods-and-patterns",
    color: "#4D88FF",
  },
  {
    label: "Platforms & Operations",
    value: "platforms-and-aoe-services",
    color: "#F54562",
  },
  {
    label: "Tools",
    value: "tools",
    color: "#FF781F",
  },
];

const TableCell = ({
  items,
  label,
  align,
  alignLeft,
  color,
}: {
  items: Item[];
  label: string;
  align: string;
  alignLeft: boolean;
  color: string;
}) => (
  <div
    className={`cell ${
      align === "center" ? "align-center" : "align-space-between"
    }`}
  >
    {alignLeft && <div className="label text-left">{label}</div>}
    <div
      className={`items-container ${
        align === "center"
          ? ""
          : alignLeft
          ? "justify-flex-end"
          : "justify-flex-start"
      }`}
    >
      {items ? (
        items.map((x: Item, i: number) => (
          <a key={i} href={`/_/_/${x.quadrant}/${x.name}`}>
            <div
              className="cell-item"
              style={{ backgroundColor: color }}
              data-tip={x.title}
              data-background-color={color}
              data-text-color={"var(--color-white)"}
            ></div>
          </a>
        ))
      ) : (
        <></>
      )}
    </div>
    {!alignLeft && <div className="label text-right">{label}</div>}
  </div>
);

export default function ComparisonTable({
  groupOneItems,
  commonItems,
  groupTwoItems,
}: {
  groupOneItems: any;
  commonItems: any;
  groupTwoItems: any;
}) {
  return (
    <div className="comparison-table">
      {rows.map((item) => (
        <div className="row" key={item.value}>
          <TableCell
            items={groupOneItems[item.value]}
            label={item.label}
            align={"space-between"}
            alignLeft={true}
            color={item.color}
          ></TableCell>
          <TableCell
            items={commonItems[item.value]}
            label={""}
            align={"center"}
            alignLeft={true}
            color={item.color}
          ></TableCell>
          <TableCell
            items={groupTwoItems[item.value]}
            label={item.label}
            align={"space-between"}
            alignLeft={false}
            color={item.color}
          ></TableCell>
        </div>
      ))}
    </div>
  );
}
