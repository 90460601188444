import { Item } from "../../model";

export const getUniqueTags = (items: Item[]): string[] => {
  const tags: string[] = items
    .filter((item) => item.tags)
    .map((item) => item.tags)
    .flat(1) as string[];
  const uniqueTags = tags.reduce(
    (prev, curr) => (prev.includes(curr) ? prev : [...prev, curr]),
    [] as string[]
  );

  return uniqueTags;
};

export default getUniqueTags;
