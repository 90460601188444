import Select from "react-select";
import { nameDecoder, nameEncoder } from "../../helpers/dashSpaceReplace";
import { PageCategoryType } from "../../model";

export default function TeamGroupFilter({
  selectedGroup,
  selectedTeam,
  options,
  onGroupChange,
  onTeamChange,
}: {
  selectedGroup: string;
  selectedTeam: string;
  options: PageCategoryType[];
  onGroupChange: (selection: string) => void;
  onTeamChange: (selection: string) => void;
}) {
  return (
    <>
      <Select
        onChange={(selection) =>
          onGroupChange(
            nameEncoder(String(selection?.value ?? "_").toLowerCase())
          )
        }
        value={
          selectedGroup === "_"
            ? null
            : {
                label: nameDecoder(selectedGroup).toUpperCase(),
                value: selectedGroup,
              }
        }
        options={options
          .filter((x) => x.path !== "_")
          .map((x) => ({
            label: nameDecoder(x.path).toUpperCase(),
            value: x.path,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))}
        placeholder="Select group..."
        classNamePrefix="custom"
        className="custom-react-select"
        closeMenuOnSelect={true}
        isClearable
      />
      <Select
        onChange={(selection) =>
          onTeamChange(
            nameEncoder(String(selection?.value ?? "_").toLowerCase())
          )
        }
        value={
          selectedTeam === "_"
            ? null
            : {
                label: nameDecoder(selectedTeam).toUpperCase(),
                value: selectedTeam,
              }
        }
        options={options
          .find(
            (x) =>
              x.path.toLowerCase() === nameDecoder(selectedGroup).toLowerCase()
          )
          ?.child?.filter((x) => x.path !== "_")
          .map((x) => ({
            label: x.path.toUpperCase(),
            value: x.path,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))}
        placeholder="Select team..."
        classNamePrefix="custom"
        className="custom-react-select"
        closeMenuOnSelect={true}
        isClearable
        isDisabled={selectedGroup === "_"}
      />
    </>
  );
}
