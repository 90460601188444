import React from "react";
import classNames from "classnames";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Router from "./Router";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useParams,
  useLocation,
} from "react-router-dom";
import { Item, Params } from "../model";
import { Messages, MessagesProvider } from "../context/MessagesContext";
import { ConfigData } from "../config";
import { nameDecoder } from "../helpers/dashSpaceReplace";

const useFetch = <D extends unknown>(url: string): D | undefined => {
  const [data, setData] = React.useState<D>();

  React.useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data: D) => {
        setData(data);
      })
      .catch((error) => {
        console.error(`fetch ${url} failed. Did the file exist?`, error);
      });
  }, [url]);

  return data;
};

const useQuery = () => new URLSearchParams(useLocation().search);

const filterByGroups = (items: Item[], namespace: string, team: string) => {
  if (namespace === "_") {
    return items;
  } else {
    const filteredByGroup = items.filter((item) =>
      Array.isArray(item.groups)
        ? item.groups.includes(namespace)
        : item.groups === namespace
    );

    if (team === "_") return filteredByGroup;
    else {
      const filteredByTeam = filteredByGroup.filter((item) =>
        Array.isArray(item.teams)
          ? item.teams.some((x) => x.toLowerCase().includes(team.toLowerCase()))
          : item.teams === team
      );
      return filteredByTeam;
    }
  }
};

const RouterWithPageParam = ({
  items,
  releases,
  config,
}: {
  items: Item[];
  releases: string[];
  config: ConfigData;
}) => {
  const { page, namespace, team } = useParams<Params>();
  const query = useQuery();
  const filterdItems = filterByGroups(
    items,
    nameDecoder(namespace),
    nameDecoder(team)
  );

  return (
    <Router
      pageName={page}
      search={query.get("search") || ""}
      items={page === "compare" ? items : filterdItems}
      releases={releases}
      config={config}
    />
  );
};

const HeaderWithPageParam = () => {
  const { page } = useParams<Params>();

  return <Header pageName={page} />;
};

const FooterWithPageParam = ({ items }: { items: Item[] }) => {
  const { page } = useParams<Params>();

  return <Footer pageName={page} items={items} />;
};

interface Data {
  items: Item[];
  releases: string[];
}

export default function App() {
  const data = useFetch<Data>(`${process.env.PUBLIC_URL}/rd.json`);
  const messages = useFetch<Messages>(
    `${process.env.PUBLIC_URL}/messages.json`
  );
  const config = useFetch<ConfigData>(`${process.env.PUBLIC_URL}/config.json`);
  if (data && config) {
    const { items, releases } = data;
    return (
      <MessagesProvider messages={messages}>
        <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
          <Switch>
            <Route path={"/:namespace/:team/:page(.+)"}>
              <div>
                <div className="page">
                  <div className="page__header">
                    <HeaderWithPageParam />
                  </div>
                  <div className={classNames("page__content")}>
                    <RouterWithPageParam
                      config={config}
                      items={items}
                      releases={releases}
                    />
                  </div>
                  <div className="page__footer">
                    <FooterWithPageParam items={items} />
                  </div>
                </div>
              </div>
            </Route>
            <Route path={"/"}>
              <Redirect to={`/_/_/index`} />
            </Route>
          </Switch>
        </BrowserRouter>
      </MessagesProvider>
    );
  }

  return null;
}
