import React, { MouseEventHandler } from "react";
import classNames from "classnames";

import { nameEncoder } from "../../helpers/dashSpaceReplace";
import "./badge.scss";

type BadgeProps = {
  onClick?: MouseEventHandler;
  big?: boolean;
  small?: boolean;
  type: "big" | "all" | "empty" | string;
};

export default function Badge({
  onClick,
  big,
  small,
  type,
  children,
}: React.PropsWithChildren<BadgeProps>) {
  const Comp = onClick ? "a" : "span";

  return (
    <Comp
      className={classNames(
        "badge",
        `badge--${nameEncoder(type)}`,
        {
          "badge--big": big === true,
        },
        { "badge--small": small === true }
      )}
      onClick={onClick}
      href={Comp === "a" ? "#" : undefined}
    >
      {children}
    </Comp>
  );
}
