import Select, { MultiValue, ActionMeta } from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { History, LocationState } from "history";

import { Item } from "../../model";
import getUniqueTags from "./helpers";

import "./FilterSelect.scss"

interface FilterSelectProps {
  items: Item[];
}

type Option = { label: string; value: string };

export default function FilterSelect({ items }: FilterSelectProps) {
  const history = useHistory<History<LocationState>>();
  const location = useLocation();

  const tagsToUrlSearchString = (tags: string[]): string => {
    const newSearch = new URLSearchParams();
    tags.forEach(tag => {
      newSearch.append("tags", tag);
    });
    return newSearch.toString();
  }

  const getSearchParameters = (
    selection: MultiValue<Option>
  ): string | undefined => {
    const tags = selection.map((option) => option.value);
    return tags.length === 0 ? undefined : tagsToUrlSearchString(tags);
  };

  const urlSearchTags: string[] = new URLSearchParams(window.location.search).getAll("tags");

  const defaultValues: Option[] = getUniqueTags(items)
    .filter((tag) => urlSearchTags.includes(tag))
    .map((tag: string) => ({
      value: tag,
      label: tag,
    }));

  const options: Option[] = getUniqueTags(items)
    .map((tag: string) => ({
      value: tag,
      label: tag,
    }))
    .filter(
      (option) =>
        !defaultValues.find((defaultVal) => defaultVal.value === option.value)
    );

  type onChange = (
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => void;

  const selectChange: onChange = (selection) =>
    history.replace({
      pathname: location.pathname,
      search: getSearchParameters(selection),
    });

  return (
    <Select
      onChange={selectChange}
      defaultValue={defaultValues}
      options={options}
      isMulti
      placeholder="Select tags..."
      classNamePrefix="custom"
      className="custom-react-select"
      closeMenuOnSelect={false}
    />
  );
}